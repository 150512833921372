import * as client_hooks from '../../../src/hooks.client/index.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [0];

export const dictionary = {
		"/(marketing)": [25,[6]],
		"/(auth)/action": [9],
		"/(marketing)/(legal)/aup": [27,[6,8]],
		"/(marketing)/(blog)/blog/[...slug]": [~26,[6,7]],
		"/(console)/console/(root)": [12,[2,3]],
		"/(console)/console/[site=site]": [13,[2,4]],
		"/(console)/console/[site=site]/calendar": [14,[2,4]],
		"/(console)/console/[site=site]/clients": [~15,[2,4]],
		"/(console)/console/[site=site]/folder/[...path]": [16,[2,4,5]],
		"/(console)/console/[site=site]/orders": [17,[2,4]],
		"/(console)/console/[site=site]/orders/[id]": [18,[2,4]],
		"/(console)/console/[site=site]/pricing": [19,[2,4]],
		"/(console)/console/[site=site]/reports": [20,[2,4]],
		"/(console)/console/[site=site]/settings": [~21,[2,4]],
		"/(console)/console/[site=site]/stripe": [~22,[2,4]],
		"/(console)/console/[site=site]/team": [23,[2,4]],
		"/(marketing)/(legal)/dmca": [28,[6,8]],
		"/(marketing)/features": [31,[6]],
		"/(marketing)/(legal)/privacy": [29,[6,8]],
		"/(console)/profile": [24,[2]],
		"/(auth)/recover": [10],
		"/(auth)/register": [11],
		"/(marketing)/(legal)/terms": [30,[6,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';